define("creators-web-app/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p6si1s6T",
    "block": "[[[10,\"meta\"],[14,\"http-equiv\",\"Content-Type\"],[14,\"content\",\"text/html; charset=utf-8\"],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"http-equiv\",\"X-UA-Compatible\"],[14,\"content\",\"IE=edge\"],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"viewport\"],[14,\"content\",\"width=device-width, initial-scale=1, maximum-scale=1\"],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"http-equiv\",\"expires\"],[14,\"content\",\"0\"],[12],[13],[1,\"\\n\"],[10,\"link\"],[14,\"rel\",\"icon\"],[14,6,\"/assets/favicon.ico?\"],[14,4,\"image/x-icon\"],[12],[13],[1,\"\\n\"],[10,\"title\"],[12],[1,[33,0,[\"title\"]]],[13],[1,\"\\n\"],[8,[39,1],null,[[\"@headTags\"],[[33,0,[\"headTags\"]]]],null]],[],false,[\"model\",\"head-tags\"]]",
    "moduleName": "creators-web-app/templates/head.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});